import React, { Fragment } from 'react'
import Breadcrumb from '../common/breadcrumb'
import styled from '@emotion/styled'
import TarifasComunes from './comunes-v1.5.pdf'
import TarifasEspanol from './rates_spanish_15032024v17.pdf'
import TarifasIngles from './rates_english_15032024v17.pdf'
import DisclaimerAnuncio from '../common/disclaimer-anuncios'

const Titulo = styled.h5`
    padding: 0px 38px;
    background-color: #004265;
    font-size: 20px;
    border-radius: 4px;
    color: #ffffff;
    margin-left: -2%;
    margin-right: -2%;
`

const MainComponent = (props) => {
    return (
        <Fragment>
            <DisclaimerAnuncio {...props} />
            <Titulo>
                <Breadcrumb title='Tarifas' parent='Servicios' />
            </Titulo>
            <div className='container-fluid'>
                <div className='col-sm-12 col-xl-12'>
                    <div className='row mt-3'>
                        <div className='col-sm-12 col-xl-6'>
                            <div className='card'>
                                <div style={{ backgroundColor: '#004265' }}>
                                    <h5 className='m-3 text-white'>{'Esquema de tarifas'}</h5>
                                </div>
                                <div className='card'>
                                    <div className='col-sm-12 col-xl-12'>
                                        <div>
                                            <div className='card card-absolute'>
                                                <div style={{ backgroundColor: '#004265' }}>
                                                    <h5 className='text-white m-3'>
                                                        {'Tarifas español'}
                                                    </h5>
                                                </div>
                                                <div className='control-section'>
                                                    <embed
                                                        src={TarifasEspanol}
                                                        height='500px'
                                                        width='100%'
                                                        name='embed_content'
                                                        type='application/pdf'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-sm-12 col-xl-12'>
                                        <div>
                                            <div className='card card-absolute'>
                                                <div style={{ backgroundColor: '#004265' }}>
                                                    <h5 className='text-white m-3'>
                                                        {'Rate schedule english'}
                                                    </h5>
                                                </div>
                                                <embed
                                                    src={TarifasIngles}
                                                    height='500px'
                                                    width='100%'
                                                    name='embed_content'
                                                    type='application/pdf'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12 col-xl-6'>
                            <div className='card'>
                                <div style={{ backgroundColor: '#004265' }}>
                                    <h5 className='m-3 text-white'>
                                        {'Tarifario de servicios más comunes'}
                                    </h5>
                                </div>
                                <div className='card-body'>
                                    <embed
                                        src={TarifasComunes}
                                        height='1200px'
                                        width='100%'
                                        name='embed_content'
                                        type='application/pdf'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default MainComponent
